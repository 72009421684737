@import '../../styles/common/common.scss';

.ps_container {
    width: 100%;
    min-height: rem(350px);
    margin: rem(60px) auto;
    position: relative;
    justify-content: center;
    align-items: center;
    color: var(--utmb-color-default);
    background: none;

    @include bp(lg) {
        margin: rem(100px) auto;
        min-height: rem(228px);
    }
}
