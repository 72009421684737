@import '../../styles/common/common.scss';

// ----------------------------------------
//
// Slider Container

.ps_slide_container {
    height: rem(390px);
    // Show box-shadow overflow
    padding: rem(20px) rem(16px);
    // Left Offset
    // padding-left: rem(336px);
    width: 100%;
    color: var(--utmb-color-default);
    text-align: left;
    z-index: 99 !important;
    overflow: hidden !important;

    @include bp(md) {
        padding: rem(20px);
    }

    @include bp(lg) {
        height: rem(268px);
    }
}

// ----------------------------------------
//
// Slide Item Padding

.ps_card {
    padding: rem(16px) rem(27px) rem(60px);
    width: 100%;
    background-color: var(--utmb-color-white);

    @include bp(lg) {
        width: unset;
        padding: rem(37px) rem(47px) rem(73px);
    }

    &:hover {
        cursor: pointer;
    }

    .moreinfo {
        position: absolute;
        bottom: rem(10px);
        right: rem(10px);
    }
}

// ----------------------------------------
//
// Slide Item

.ps_slide_item {
    position: relative;
    background: none;
    box-shadow: none;
    min-width: rem(238px) !important;
    max-width: rem(259px);
    padding-right: rem(20px);
    overflow: visible;

    @include bp(md) {
        min-width: rem(270px) !important;
        padding-right: rem(30px);
        -webkit-flex: unset;
        flex: unset;
    }

    @include bp(lg) {
        box-sizing: border-box !important;
        min-width: rem(700px) !important;
    }

    &:hover {
        cursor: pointer;
    }

    &:last-child {
        @include bp(lg) {
            padding-right: rem(150px);
            min-width: rem(857px) !important;

            .ps_link {
                right: rem(175px);
            }
        }
    }
}

// ----------------------------------------
//
// Slide Content

.ps_slide_content {
    flex: 1;
    flex-direction: column;
    height: 100%;
    box-shadow: 0 0 rem(15px) rgba(65, 80, 92, 0.2);
    background: var(--utmb-color-blend);
    overflow: hidden;

    @include bp(lg) {
        flex-direction: row;
        background: var(--utmb-color-white);
    }

    & div img {
        transform: scale(1, 1);
        -webkit-transform: scale(1, 1);
        -moz-transform: scale(1, 1);
        -o-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
    }

    &:hover div img {
        cursor: pointer;
        transform: scale(1.1, 1.1);
        -webkit-transform: scale(1.1, 1.1);
        -moz-transform: scale(1.1, 1.1);
        -o-transform: scale(1.1, 1.1);
        -ms-transform: scale(1.1, 1.1);
        transition: all 0.2s ease-in-out;
        -webkit-transition: all 0.2s ease-in-out;
        -moz-transition: all 0.2s ease-in-out;
        -o-transition: all 0.2s ease-in-out;
        -ms-transition: all 0.2s ease-in-out;
    }

    .image_container {
        position: relative;
    }
}

// Main Paragraph
.ps_content {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    //
    @include bp(lg) {
        -webkit-line-clamp: 4;
    }
}

// ----------------------------------------
//
// Thumbnail Image Width

.ps_slide_content > *:first-child {
    width: 100%;
    height: rem(150px);

    @include bp(lg) {
        width: rem(308px) !important;
        height: unset;
    }
}

// ----------------------------------------
//
// Navigation Buttons

.ps_link {
    background-color: transparent;
    border: rem(1px) solid transparent;
    //
    position: absolute;
    height: rem(30px);
    bottom: 5%;
    right: 15%;

    @media (orientation: landscape) {
        bottom: rem(10px);
        top: unset;
        right: rem(45px);

        @include bp(lg) {
            top: auto;
            bottom: rem(24px);
            right: rem(55px);
        }
    }

    &:link,
    &:visited,
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        opacity: 0.8;
    }

    &:hover {
        cursor: pointer;
        opacity: 1;
    }

    &:focus {
        outline: -webkit-focus-ring-color auto rem(4px) !important;
        box-shadow: 0 0 rem(1000px) rgba(0, 0, 0, 0.1) inset;
        border-radius: 0 !important;
    }
}
